import { createApp } from 'vue'
import App from './App.vue'

import DSHeader from "@/components/DSHeader.vue";
import 'element-plus/dist/index.css'
import store from './store'
import ElementPlus from 'element-plus';
// import ZhLocale from 'element-plus/lib/locale/lang/zh-cn'; // 中文
// import EhLocale from 'element-plus/lib/locale/lang/en'; // 英文
import locale from 'element-plus/es/locale/lang/zh-cn'; // 中文 //安装的element-plus 2.4.1 依赖, 里面部分代码路径更新了, 导致插件中上两行的代码的配置有问题
//import locale from 'element-plus/es/locale/lang/en'; // 英文
import i18n from "./language";
// import I18n from "@/lang/index"; //引入i18n组件

import router from "./router";
import axios from 'axios'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
axios.defaults.baseURL = "/server" //解决每次发请求都要填写完整的请求路径


//App.config.globalProperties.$http = axios;//解决每个组件都需要导入axios ，相当于定义了全局变量$http
const app = createApp(App).use(ElementPlus).use(ElementPlusIconsVue).use(router).use(store).use(i18n)
// 自己在引入icon时，安装官网的方式进行添加组件，在 el-input 中 prefix-icon 添加icon后，明显看出有空间用于显示 icon，但却不显示图标。
//  main.js 文件中，因为是后加的代码。component方法，写在了后面。此时createApp，已经挂到了app.vue中。
// 以至于后面的组件注入，无法被使用。所以所有组件注册完毕后在加载app
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')
//// 将 store 实例作为插件安装
