export default {
    "首页": "Home",
    "产品": "Products",
    "公司简介": "Company Overview",
    "联系我们": "Contact us",
    "搜索": "search",
    "姓名": "Name",
    "规格书": "specifation",
    "查看": "view",
    "未搜索到任何相关数据": "No relevant data found",
    "联系售后": "Contact the sales if you need {pn} spec.",
    "公司简介": "About the Company",
    "简介内容": "Deep Simplicity Technology Co., Ltd was established in 2015. Focus on high-performance analog and mixed-signal chip development and design. The founding team members are all from the famous chip design companies,  the average of their design and development experience is  more than 15 years.They have many years of research and development, operation and management experience in the electronics industry. The products are widely used in communication base stations, servers, medical equipment, industry, wearable equipment, high-end lighting and other fields.",
    "联系我们": "Contact Us",
    "联系电话": "Mobile",
    "座机": "Tel",
    "联系人": "Contact",

};