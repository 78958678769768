// import VueRouter from "vue-router";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Vue from "vue";
import Carousel from "@/components/Carousel.vue"
import Home from "@/components/Home.vue"
import LDOProduct from "@/components/product/LDOProduct.vue"
import LEDDriverProduct from "@/components/product/LEDDriverProduct.vue"
import BuckProduct from "@/components/product/BuckProduct.vue"
import BoostProduct from "@/components/product/BoostProduct.vue"
import Contact from "@/components/Contact.vue"
import CompanyOverview from "@/components/CompanyOverview.vue";
import Search from "@/components/Search.vue";
// { path: '/product/:productName', component: Product },
const routes = [
    { path: '/', component: Home },
    { path: '/product/LDO', component: LDOProduct },
    { path: '/product/Buck', component: BuckProduct },
    { path: '/product/Boost', component: BoostProduct },
    { path: '/product/LED Driver', component: LEDDriverProduct },
    { path: '/contact', component: Contact },
    { path: '/overview', component: CompanyOverview },
    { path: '/search', component: Search },
]

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})

export default router;