<template>
  <div class="about-us">
    <h2 class="title">{{$t("公司简介")}}</h2>
    <p class="intro">{{$t("简介内容")}}</p>
    <!-- <div class="features">
      <div class="feature" v-for="(item,index) in features" :key="index">
        <i :class="item.icon"></i>
        <h4 class="feature-title">{{item.title}}</h4>
        <p class="feature-content">{{item.content}}</p>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "CompanyOverview",
  data() {
    return {
      Index: 0,
      introduction:
        " 上海奥简微电子科技有限公司，成立于2015年，总部位于上海，台湾新竹设立研发中心，江阴设立生产运营中心。公司着力于高性能模拟芯片的研发设计，我们的核心研发和管理团队来自业界顶尖半导体设计公司，致力于为客户提供快速、高效、高性价比的电源管理方案和服务。",
      serviceIntro:
        "网络综合公司，提供包括网络基础服务(如域名、主机、邮箱)和网络增值服务(如网站建设和推广，网站优化)等业务",
      features: [
        {
          icon: "fa fa-lightbulb-o",
          title: "品牌创意",
          content: "为企业设计独特的并能完美呈现企业价值观的视觉"
        },

        {
          icon: "fa fa-line-chart",
          title: "整合营销",
          content: "通过市场进入分析、制定网络营销战略、网络营销实施"
        },
        {
          icon: "fa fa-shopping-cart",
          title: "电子商务",
          content: "根据企业需求，开设不同的销售渠道，通过网上直销"
        },
        {
          icon: "fa fa-desktop",
          title: "网页设计",
          content: "通过网站建设、只能建站、域名主机、企业邮箱"
        },
        {
          icon: "fa fa-tachometer",
          title: "网站优化",
          content: "网站推广是使将网站推广到国内各大知名网站和搜索引擎"
        },
        {
          icon: "fa fa-server",
          title: "网页架设",
          content: "通过绑定域名和服务器，把网站展现给全世界"
        }
      ]
    };
  }
};
</script>

<style>
.about-us {
  padding-bottom: 32px;
  width: 80%;
  margin: 0px auto;
}
.title {
  text-align: center;
}
.intro {
  font-size: 20px;
  line-height: 40px;
}
.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 126px 126px;
  column-gap: 5vw;
}
.feature {
  display: grid;
  /*自定义行和列  icon占两行，title占一行，content占一行*/
  grid-template-areas:
    "icon title"
    "icon content";
  /*<i>标签占64px*/
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr 3fr;
}
.feature i.fa {
  grid-area: icon;
  color: var(--primary-color);
  font-size: 34px;
}
.feature-title {
  grid-area: title;
  font-size: 18px;
  color: var(--text-color-darker);
}
.feature-content {
  grid-area: content;
  font-size: 14px;
  color: var(--text-color-dark-gray);
}
</style>