<template>
  <el-row class="header">
    <el-col :lg="8" :xs="24">
      <img src="@/assets/image/logo.png" alt="Logo" />
    </el-col>

    <el-col :lg="8" :xs="24">
      <!-- <el-input
        class="search"
        :placeholder="$t('搜索')"
        prefix-icon="Search"
        v-model="keywords"
        @keyup.enter="goSearch()"
        clearable
      />-->
    </el-col>
    <el-col :lg="2" :xs="0"></el-col>
    <el-col :lg="6" :xs="24">
      <!-- <el-tooltip :content="'Switch value: ' + value" placement="top"> -->
      <div @click="handleSwitchChange(value)">
        <el-switch
          v-model="value"
          active-color="#409EFF"
          inactive-color="#409EFF"
          width="40"
          :active-value="0"
          :inactive-value="1"
          active-text="中文"
          inactive-text="English"
        ></el-switch>
      </div>
      <!-- </el-tooltip> -->
    </el-col>
  </el-row>
</template>

<script>
import axios from "axios";
import store from "@/store";
export default {
  name: "DSHeader",
  data() {
    return {
      // value: "0",
      keywords: ""
    };
  },

  computed: {
    value() {
      // 计算数值的总和
      console.log("store.state.language", store.state.language);
      var newValue;
      if (store.state.language == "zh" || store.state.language == "zh-CN")
        newValue = 0;
      else newValue = 1;

      return newValue;
    }
  },
  methods: {
    //整个语言的控制是通过store里的language来决定的，刚开始的默认值是根据navigator.language取的浏览器默认语言
    //语言开关的值是使用了计算属性value，此值是根据store里的language计算得来的。
    handleSwitchChange(value) {
      console.log("value = do" + value);
      if (value == 1) {
        //当前是英文
        this.$i18n.locale = "zh"; //改变main.js 中的i18n（自定义语言）的设置
        store.commit("setLanguage", "zh"); //改变App.vue中的el-config-provider :locale
      } else {
        //当前是中文
        this.$i18n.locale = "en";
        store.commit("setLanguage", "en");
      }
      // Perform any desired actions based on the switch value
    },
    goSearch() {
      if (this.keywords.value !== "") {
        this.$router.push({ path: "/search" });
        axios.get("/product/alltype").then(response => {
          console.log(response.data);
        });
      }
    }
  }
};
</script>

<style>
/* 样式可以根据需要进行调整 */
.header {
  display: flex;
  align-items: center;
}
.search {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}
.el-switch__label--left {
  position: relative;
  left: 100px;
  color: #fff;
  z-index: -1111;
}
.el-switch__label--right {
  position: relative;
  right: 100px;
  color: #fff;
  z-index: -1111;
}
.el-switch__label--right.is-active {
  z-index: 1111;
  color: #0a0909 !important;
}
.el-switch__label--left.is-active {
  z-index: 1111;
  color: #0a0909 !important;
}
</style>