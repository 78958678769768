import request from '@/utils/request'

export function fetchListFromleddriverProduct(params) {
  return request({
    url: '/product/leddriver/all',
    method: 'get',
    params
  })
}

export function fetchListFromLDOProduct(params) {
  return request({
    url: '/product/ldo/all',
    method: 'get',
    params
  })
}



export function fetchListFromBuckProduct(params) {
  return request({
    url: '/product/buck/all',
    method: 'get',
    params
  })
}

export function fetchListFromBoostProduct(params) {
  return request({
    url: '/product/boost/all',
    method: 'get',
    params
  })
}
