<template>
  <!--轮播图-->
  <el-carousel
    v-if="swiperList.length"
    class="swiper-container"
    trigger="click"
    :height="dataHeight"
    :interval="4000"
  >
    <el-carousel-item class="swiper-item" v-for="(item, index) in swiperList" :key="index">
      <div class="container">
        <img :src="baseUrl+item.image" alt="Slide Image" fit="fill" />
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import axios from "axios";
export default {
  name: "Carousel",
  data() {
    return {
      swiperList: [],
      baseUrl: "/server"
    };
  },
  props: {
    dataHeight: {
      type: String,
      default: "600px" //vue 设置el-carousel的高度
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get("/carousel/all").then(response => {
        this.swiperList = response.data;
      });

      // getList().then(response => {
      //   this.list = response.data.items;
      //   this.listLoading = false;
      // });
    }
  }
};
//console.log(swiperList);
</script>

<style>
.container {
  display: flex;
  justify-content: center;
}
/*轮播图*/
.swiper-container {
  width: 100%;
  margin: auto;
  padding-top: 0px;
  min-height: auto !important;
}
.swiper-item {
  height: 600px;
}

.swiper-container:deep(.el-carousel__container.el-carousel__indicators.el-carousel__indicators--outside) {
  display: inline-block;
}
</style>