<template>
  <div class="main">
    <!--轮播图-->
    <DSHeader></DSHeader>

    <el-menu
      :default-active="activeIndex2"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#4682B4"
      text-color="#fff"
      active-text-color="#ffd04b"
      @select="handleSelect"
    >
      <el-menu-item index="1">
        <router-link to="/">{{$t("首页")}}</router-link>
      </el-menu-item>

      <el-sub-menu popper-class="productMenu" index="2">
        <template #title>{{$t("产品")}}</template>
        <!-- <el-menu-item index="2-1">
          <router-link :to="getProductPath('产品1')">{{$t("产品1")}}</router-link>
        </el-menu-item>
        <el-menu-item index="2-2">{{$t("产品2")}}</el-menu-item>
        <el-menu-item index="2-3">{{$t("产品3")}}</el-menu-item>-->

        <el-menu-item v-for="(item) in  subMenu" :key="item.id" :index="'2'+item.id">
          <router-link :to="getProductPath(item.name)">{{$t(item.name)}}</router-link>
        </el-menu-item>
      </el-sub-menu>
      <!-- <el-menu-item index="3">
        <router-link to="/overview">{{$t("公司简介")}}</router-link>
      </el-menu-item>-->
      <el-menu-item index="4">
        <router-link to="/contact">{{$t("联系我们")}}</router-link>
      </el-menu-item>
    </el-menu>
    <router-view></router-view>
    <!-- <Carousel></Carousel> -->
  </div>
  <!-- <img src="@/assets/logo.png" alt="Slide Image" /> -->
</template>

<script>
import Home from "@/components/Home.vue";
import DSHeader from "@/components/DSHeader.vue";
import Contact from "@/components/Contact.vue";
import CompanyOverview from "@/components/CompanyOverview.vue";
import axios from "axios";
export default {
  name: "Main",
  created() {
    axios.get("/product/alltype").then(response => {
      this.subMenu = response.data;
    });
  },
  data() {
    return {
      subMenu: []
    };
  },
  methods: {
    getProductPath(name) {
      //  console.log("name = " + name);
      return "/product/" + this.$t(name);
    }
  },
  components: {
    DSHeader,
    Contact,
    Home,
    CompanyOverview
  }
};
</script>

<style>
a {
  text-decoration: none;
  color: #fff;
}

.el-sub-menu__title.el-tooltip__trigger {
  font-size: 20px;
  font-weight: bold !important;
}

.el-menu-item {
  font-size: 20px !important;
  font-weight: bold;
  width: 13%;
}
@media (max-width: 768px) {
  .el-menu-item {
    width: 20%;
  }
}

.el-menu-item:hover {
  background-image: linear-gradient(to right, #1b82db, #20dfe1);
}
.el-sub-men:hover {
  background-image: linear-gradient(to right, #1b82db, #20dfe1);
}

.el-menu--popup-bottom-start .el-menu-item:hover {
  color: rgb(8, 8, 8) !important;
  background-image: linear-gradient(to right, #1b82db, #20dfe1);
  width: 100%; /* 设置选中效果，相对于父*/
}

/* 设置子菜单的背景，color不行，要imag */
.el-menu--popup-bottom-start .el-menu-item {
  /* */
  /* background-image: linear-gradient(to right, #1b82db, #20dfe1); */
  background-image: linear-gradient(to right, #96989b, #9b9e9e);
  color: #000 !important;
  opacity: 0.4;
}
</style>