<template>
  <div>
    <h3 class="center">Boost</h3>
    <el-table
      :header-cell-style="{ 'text-align': 'center' ,background:'#eef1f6',color:'#606266'}"
      :cell-style="{ 'text-align': 'center','border':'1px solid #eef1f6' }"
      :data="tableData"
      fit
      stripe
      border
      style="width: 100%"
    >
      <el-table-column fixed label="PN" prop="pn" width="auto" :min-width="120" />
      <el-table-column :label="'Vin.Min\n(V)'" prop="vinMin" width="auto" :min-width="120" />
      <el-table-column :label="'Vin.Max\n(V)'" prop="vinMax" width="auto" :min-width="120" />
      <el-table-column :label="'Vout.Max\n(V)'" prop="voutMax" width="auto" :min-width="120" />
      <el-table-column :label="'fosc\n(KHz)'" prop="fosc" width="auto" :min-width="120" />
      <el-table-column :label="'VREF\n(V)'" prop="vref" width="auto" :min-width="120" />
      <el-table-column :label="'IQ\n(uA)'" prop="iq" width="auto" :min-width="120" />

      <el-table-column :label="'Ilim\n(A)'" prop="ilim" width="auto" :min-width="120" />
      <el-table-column :label="'NRdson\n(m)'" prop="nrdson" width="auto" :min-width="120" />
      <el-table-column label="Package" prop="pack" width="auto" :min-width="120" />
      <el-table-column :label="$t('规格书')" width="auto" :min-width="120">
        <template #default="scope">
          <el-button size="small" @click="showSpeciFile(scope.row)">{{$t('查看')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination background layout="->,prev, pager, next,total," :total="1000" class="mt-40" /> -->
    <el-config-provider :locale="zhCn">
      <el-pagination
        v-show="total>0"
        background
        layout="->,prev, pager, next,  total"
        :total="total"
        :pager-count="7"
        @current-change="handleCurrentChange"
        :current-page="listQuery.pageIndex"
        :page-size="listQuery.pageLimit"
      />
    </el-config-provider>
  </div>
</template>

<script  >
import { fetchListFromBoostProduct } from "@/api/product.js";
export default {
  name: "BoostProduct",
  props: ["title"],
  data() {
    return {
      tableData: [],
      total: 0,
      listLoading: false, // true
      listQuery: {
        pageIndex: 1,
        pageLimit: 5
      }
    };
  },
  methods: {
    handleCurrentChange(currentPage) {
      this.listQuery.pageIndex = currentPage;
      this.getList();
      // console.log(this.currentPage);
    },
    getList() {
      this.listLoading = true;
      fetchListFromBoostProduct(this.listQuery).then(response => {
        this.tableData = response.data.items.records;
        this.tableData.forEach(item => {
          //forEach循环
          item.createTime = +new Date(item.createTime);
        });
        this.total = response.data.items.total;
        this.listLoading = false;
        // Just to simulate the time of the request
        // setTimeout(() => {
        //   this.listLoading = false;
        // }, 1.5 * 1000);
      });
    },
    showSpeciFile(row) {
      console.log(row.speciUrl);
      if (row.speciUrl === "") {
        // this.$alert("", `${row.pn}型号规格书请联系我们的售后`, {
        // this.$alert("", `$t("联系售后"，{pn:row.pn})`, {
        this.$alert("", this.$t("联系售后", { pn: row.pn }), {
          confirmButtonText: "确定",
          distinguishCancelAndClose: true
        })
          .then(function() {
            // TODO 确认通过执行逻辑
          })
          .catch(function(e) {
            if (e == "cancel") {
              // TODO 确认不通过执行逻辑
            } else if (e == "close") {
              // TODO 右上角X的执行逻辑
            }
          });
      } else {
        window.open("/server" + row.speciUrl);
      }
    }
  },
  created() {
    this.getList();
  }
};
</script>
<style>
/**
改变边框颜色
 */
.el-table--border,
.el-table--group {
  border: 2px solid #ddd !important;
}
/**
改变表格内竖线颜色
 */
.el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ddd !important;
}
/**
改变表格内行线颜色
 */
.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #ddd !important;
}
/* el-table中label换行，以及表头表格内容换行,都需要修改el-table的css ； */
.el-table th > .cell {
  white-space: pre-wrap;
}
/* .el-table thead tr th {
  border-color: #d8a879;
} */
/* g改变表头字体颜色 */
.el-table thead th {
  background-color: #409eff;
  color: black;
  font-weight: bold;
}

.el-pagination {
  margin-top: 80px;
}
.tableCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
