<template>
  <el-config-provider :locale="locale">
    <div id="app">
      <Main></Main>
    </div>
  </el-config-provider>
</template>

<script>
import Main from "@/views/Main.vue";
import zh from "element-plus/es/locale/lang/zh-cn";
import En from "element-plus/es/locale/lang/en";
import store from "./store";
export default {
  name: "App",
  components: {
    Main
  },
  data() {
    return {
      // locale: zh
    };
  },
  computed: {
    locale() {
      console.log("app computed locale", store.state.language);
      if (store.state.language == "zh" || store.state.language == "zh-CN")
        return zh;
      else return En;
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
