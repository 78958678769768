export default {
    "首页": "首页",
    "产品": "产品",
    "公司简介": "公司简介",
    "联系我们": "联系我们",
    "搜索": "搜索",
    "姓名": "姓名",
    "规格书": "规格书",
    "查看": "查看",
    "未搜索到任何相关数据": "未搜索到任何相关数据",
    "联系售后": "如果您需要{pn}型号规格书请联系我们的售后",
    "公司简介": "公司简介",
    "简介内容": "上海奥简微电子科技有限公司，成立于2015年。专注于高性能模拟及混合信号芯片开发设计。创始团队成员均来自世界知名芯片设计公司，平均设计开发经验大于十五年，有多年电子行业研发，运营及管理经验。产品广泛应用于通信基站，服务器，医疗设备，工业，穿戴设备，高端照明等领域。",
    "联系我们": "联系我们",
    "联系电话": "联系电话",
    "座机": "座机",
    "联系人": "联系人",


};