<template>
  <div>
    <Carousel></Carousel>
    <CompanyOverview></CompanyOverview>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import CompanyOverview from "@/components/CompanyOverview.vue";
export default {
  name: "Home",
  components: {
    Carousel,
    CompanyOverview
  }
};
</script>
