import { createI18n } from "vue-i18n";
import zh from './lang/zh.js';
import en from './lang/en.js';
const i18n = createI18n({
    legacy: false,
    locale: "zh", // 初始化配置语言
    messages: {
        zh,
        en,
    },
});

export default i18n;