<template>
  <div class="container">
    <div>
      <img
        class="img"
        src="https://img0.baidu.com/it/u=865112164,3811307004&fm=253&fmt=auto&app=138&f=JPEG?w=900&h=305"
      />
    </div>
    <div class="info">
      <h2>
        <el-icon :size="30">
          <ChatDotRound />
        </el-icon>
        {{$t("联系我们")}}
      </h2>
      <h4>{{$t("座机")}}：021-58380213</h4>
      <h4>{{$t("联系人")}}：frankgao</h4>
      <h4>{{$t("联系电话")}}：18616312228</h4>
      <!-- <h4>联系地址：中国（上海）自由贸易试验区临港新片区环湖西二路888号C楼</h4> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      longitude: 108.961495,
      latitude: 34.230523,
      scale: 13,
      covers: [
        {
          latitude: 34.224276,
          longitude: 108.955297,
          iconPath: "/static/location.png",
          width: 30,
          height: 30
        }
      ]
    };
  },
  components: {},
  methods: {
    phone() {
      uni.makePhoneCall({
        phoneNumber: "114" //仅为示例
      });
    }
  }
};
</script>

<style >
.contact .img {
  width: 750px;
  height: 320px;
}

.info {
  text-align: center;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

