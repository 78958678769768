import { createApp } from 'vue'
import { createStore } from 'vuex'

// 创建一个新的 store 实例
const store = createStore({
  state() {
    return {
      count: 0,
      todos: [
        { id: 1, text: 'eat', done: true },
        { id: 2, text: 'sleep', done: false }
      ],
      // language: 'zh',
      language: navigator.language,

    }
  },
  mutations: {
    increment(state, n) {  //带参数，提交载荷Payload
      state.count += n
    },
    //通过这个方法来修改vuex中的数据
    setLanguage(state, lang) {
      // 给缓存中添加数据
      //setItem(LANG, lang);
      state.language = lang;
    },
  },
  getters: {
    doneTodos: state => {
      return state.todos.filter(todo => todo.done)
    }
  },
  acitons: {
    increment(context) {
      context.commit('increment')
    }
  }
})

export default store