<template>
  <div>
    <el-table
      :show-header="isEmpty"
      :data="tableData"
      header-align="right"
      :empty-text="$t('未搜索到任何相关数据')"
      fit="true"
    >
      <el-table-column prop="id" label="Date" width="150" />
      <el-table-column prop="username" label="Name" width="120" />
      <el-table-column prop="userpass" label="State" width="120" />
      <el-table-column prop="birthday" label="City" width="120" />
      <el-table-column label="Operations" width="120">
        <template #default="scope">
          <el-button
            link
            type="primary"
            size="small"
            @click.prevent="deleteRow(scope.$index)"
          >Remove</el-button>
        </template>
      </el-table-column>

      <div slot="empty" style="text-align: left;">暂无数据</div>
    </el-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Search",
  created() {},
  data() {
    return {
      tableData: []
    };
  },
  computed: {
    isEmpty() {
      return !(this.tableData.length == 0);
    }
  }
};
</script>
<style>
/** 改变边框颜色 */
.el-table--border,
.el-table--group {
  border: 2px solid #0b4698 !important;
}
/**
改变表格内竖线颜色
 */
.el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ddd !important;
}
/**
改变表格内行线颜色
 */
.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #ddd !important;
}

.el-table thead tr th {
  border-color: #0b4698;
}
/* g改变表头字体颜色 */
.el-table thead {
  color: black;
  font-weight: bold;
}
</style>
